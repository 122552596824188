// src/FormComponent.js

import React, { useState, useEffect } from 'react';
import './FormComponent.css'; // Import the CSS file

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    textarea1: '',
    textarea2: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [submissionMessage, setSubmissionMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email) errors.email = 'Email is required';
    if (!formData.phone) errors.phone = 'Phone number is required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    if (!validateForm()) return;

    setSubmissionStatus('submitting');

    try {
      const response = await fetch('http://localhost:5000/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        setSubmissionStatus('success');
        setSubmissionMessage('Form submitted successfully.');
      } else {
        const errorData = await response.json();
        setSubmissionStatus('error');
        setSubmissionMessage(errorData.error || 'An error occurred while submitting the form.');
      }
    } catch (error) {
      setSubmissionStatus('error');
      setSubmissionMessage('An error occurred while submitting the form.');
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setTabSwitchCount(prev => prev + 1);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (tabSwitchCount > 3) {
      handleSubmit();
    }
  }, [tabSwitchCount]);

  return (
    <div className="form-container">
      {submissionMessage && (
        <div className={`message ${submissionStatus}`}>
          <h2>{submissionStatus === 'success' ? 'Success!' : 'Error!'}</h2>
          <p>{submissionMessage}</p>
        </div>
      )}
      {submissionStatus !== 'success' && submissionStatus !== 'error' && (
        <form onSubmit={handleSubmit}>
          <section className="form-section personal-info">
            <h2>Personal Info</h2>
            <div className="form-group">
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="form-control"
                />
                {formErrors.name && <p className="error-text">{formErrors.name}</p>}
              </label>
            </div>
            <div className="form-group">
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control"
                />
                {formErrors.email && <p className="error-text">{formErrors.email}</p>}
              </label>
            </div>
            <div className="form-group">
              <label>
                Phone:
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="form-control"
                />
                {formErrors.phone && <p className="error-text">{formErrors.phone}</p>}
              </label>
            </div>
          </section>
          <section className="form-section textarea-group">
            <div className="form-group">
              <h2>Question 1</h2>
              <label>
                Your question or comment:
                <textarea
                  name="textarea1"
                  value={formData.textarea1}
                  onChange={handleChange}
                  className="form-control large-textarea"
                />
              </label>
            </div>
            <div className="form-group">
              <h2>Question 2</h2>
              <label>
                Additional comments:
                <textarea
                  name="textarea2"
                  value={formData.textarea2}
                  onChange={handleChange}
                  className="form-control large-textarea"
                />
              </label>
            </div>
          </section>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      )}
    </div>
  );
};

export default FormComponent;
